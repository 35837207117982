import React, { Suspense, useContext, useState } from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import Redis from 'ioredis'
import cloneDeep from 'lodash/cloneDeep';
import MobileDetect from 'mobile-detect';
import dynamic from 'next/dynamic';

import * as appContext from '../../../components/AppContext';
import Checkers from '../../../helpers/class/Checkers';
import * as DataFetchHelper from '../../../helpers/DataFetchHelper';
import * as MethodHelper from '../../../helpers/MethodHelper';

import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

import { Fancybox } from '@fancyapps/ui/dist/fancybox.esm';

import BuilderHead from '../../../components/head/BuilderHead';
import { InlineStyles } from '../../../components/templates/index/partials/InlineStyles';
import {
  addCollection,
  addTag,
  addTheme, setAdults, setCheckin, setCheckout, setInfants, setIsListingPage, setIsSearchPage, setKids,
  setPageId,
  setPropertiesData,
  setPropertiesFiltersData,
  setSearchText, updatePropertyInitialAvailability
} from '../../../redux/actions';
import { wrapper } from '../../../redux/store';
import MainLayout from "../../../layouts/MainLayout";

const Header = dynamic( () => import( '../../../components/Header' ), { suspense: true });
const Properties = dynamic( () => import( '../../../components/templates/properties' ), { suspense: true });
const BottomSections = dynamic( () => import( '../../../components/BottomSections' ), { suspense: true });
const Scripts = dynamic( () => import( '../../../components/Scripts' ), { ssr: false });

export const Index = ( props ) => {

  const pageContext = useContext( appContext.page );
  pageContext.pageId = props.app_data.page_id;

  pageContext.isMobile = props.isMobile;
  pageContext.isTablet = props.isTablet;
  pageContext.isDesktop = props.isDesktop;

  pageContext.appSettings = props.app;

  const [ urlHref, setUrlHref ] = useState( null );

  React.useEffect( () => {
    Fancybox.bind( "[data-fancybox]", {
      Image: {
        zoom: false,
      },
    });
    if ( window !== undefined ) {
      setUrlHref( window.location.href + '/' );
    }
  }, [urlHref]);

  const domain = props.domain;
  const app = props.app;
  const builder = props.builder;
  const head = props.head;
  const footer = props.footer;

  const app_data = props.app_data;
  const navigation = props.navigation;
  const properties = props.properties;
  const rgbDesignColor = props.rgbDesignColor;
  const rgbSearchbarColor = props.rgbSearchbarColor;

  const isDesktop = props.isDesktop;

  const title = 'Villas Collection | ' + head.data.title;
  const meta_desc = head.data.motto;
  const image_fb_meta = Checkers.isValidArray( head.og_image ) ? head.og_image[0].image : null;

  const contactIcons = {
    phoneIcon: app.showPhoneIconOnMenu,
    chatIcon: app.showChatIconOnMenu,
    mailIcon: app.showMailIconOnMenu,
    phoneIconData: app.menuPhoneIconData,
    chatIconData: app.menuChatIconData,
    mailIconData: app.menuMailIconData
  }

  // const containerClass = Checkers.googleMapsPropertyInit( app.googleMapsApiKey, app.viewMap, 0, 0 ) ? 'container-fluid px-2 px-md-3' : 'container';
  const containerClass = 'container';

  return (
      <MainLayout {...props}>
        <InlineStyles app={app} rgbDesignColor={rgbDesignColor} rgbSearchbarColor={rgbSearchbarColor}/>
        <BuilderHead
          title={title}
          titleOfficial={head.data.titleOfficial}
          domain={urlHref}
          icon_60={head.data.icon_60}
          icon_76={head.data.icon_76}
          icon_120={head.data.icon_120}
          icon_152={head.data.icon_152}
          icon_256={head.data.icon_256}
          icon={head.data.icon}
          title_fb_meta={title}
          desc_fb_meta={meta_desc}
          image_fb_meta={image_fb_meta}
          site_name_fb_meta={head.data.title}
          lat_fb_meta={head.location.latitude}
          lon_fb_meta={head.location.longitude}
          twitter_img_alt={title + meta_desc}
          googleSchemaContext={domain}
          googleSchemaTitle={head.data.title}
          googleSchemaStreetAddress={head.location[0].address}
          googleSchemaCity={head.location[0].city}
          googleSchemaCountry={head.location[0].country}
          googleSchemaZip={head.location[0].zip}
          googleSchemaPhone={head.location[0].phone}
          designColor={app.designColor}
          font={app.font}
          manager={false}
          property={true}
        />
        {/*<Suspense>*/}
        {/*  <Header*/}
        {/*    app={app}*/}
        {/*    builder={builder}*/}
        {/*    containerClass={containerClass}*/}
        {/*    phone={head.location[0].phone}*/}
        {/*    menu={navigation.menu}*/}
        {/*    partials={navigation.partials}*/}
        {/*    showBookButtonMenu={app.showBookButtonMenu}*/}
        {/*    contactIcons={app.showContactIconsOnMenu ? contactIcons : null}*/}
        {/*    pageTitle={head.data.title}*/}
        {/*    property={true}*/}
        {/*    referer={urlHref}*/}
        {/*    zIndex={1027}*/}
        {/*  />*/}
        {/*</Suspense>*/}
        <Suspense>
          <Properties
            app={app}
            builder={builder}
            propertiesIndex={true}
            propertiesParams={props.propertiesParams}
            properties_count={props.properties_count}
            designMode={app.designMode}
            searchbarRadius={app.searchbarRadius}
            searchbarShadow={app.searchbarShadow}
            bookingSearchbarWithBedrooms={app.bookingSearchbarWithBedrooms}
            googleMapShow={app.viewMap}
            googleMapApiKey={app.googleMapsApiKey}
            propertiesFilters={props.propertiesFilters}
          />
        </Suspense>
        {/*<Suspense>*/}
        {/*  <BottomSections*/}
        {/*    isDesktop={isDesktop}*/}
        {/*    contactSection={false}*/}
        {/*    footerSection={true}*/}
        {/*    app_data={app_data}*/}
        {/*    app={app}*/}
        {/*    head={head}*/}
        {/*    locations={null}*/}
        {/*    footer={footer}*/}
        {/*    htmlSections={props.html_sections}*/}
        {/*  />*/}
        {/*</Suspense>*/}
        <Scripts/>
      </MainLayout>
  );
}

export default Index;

// export async function getServerSideProps ({ req, res }) {
export const getServerSideProps = wrapper.getServerSideProps( ( store ) => async (
  { req,
    res,
    query
  }) => {

  Checkers.fetchQueryParamateres( store, query )

  const [ isMobile, isTablet,  isDesktop ] = Checkers.getUserAgentData( store, req )

  res.setHeader(
    'Cache-Control',
    'public, s-maxage=43200, stale-while-revalidate=60'
  )
  const redis = new Redis( process.env.REDIS );
  let subdomain = null;
  const slug_data = null;

  let head = null;
  let app = null;
  let page = null;
  let app_data = null;
  let navigation = null;
  let properties_count = null;
  let locations = null;
  let footer = null;
  let rgbSearchbarColor = null;
  let rgbDesignColor = null;
  let properties_params;
  let propertiesFilters = null;
  let html_sections;

  let manifest = null;

  const domain = req.headers.host;
  const hostname_parts = req.headers.host.split( '.' );
  const parts = hostname_parts.length;

  app_data = await DataFetchHelper.fetchAppData( req, redis, res );

  if ( app_data != null ) {
    store.dispatch( setPageId( app_data.page_id ) )

    store.dispatch( setIsSearchPage(true) )

    properties_params = Checkers.getPropertiesParams( store );

    // store.dispatch(updatePropertyInitialAvailability(true))

    await DataFetchHelper.fetchPropertiesData( store, redis, app_data.page_id, properties_params );

    await DataFetchHelper.fetchSystemData( redis, app_data.page_id, store ).then( ( response ) => {
      head = response.head
      app = response.app
      page = response.page
      navigation = response.navigation
      locations = response.locations
      footer = response.footer
      html_sections = response.html_sections
    })

    await DataFetchHelper.fetchPropertiesListCountFilters( store, redis, app_data.page_id, properties_params ).then(
      ( response ) => {
        properties_count = response.properties_count
        propertiesFilters = response.propertiesFilters
      })
  }

  if ( Object.keys( head ).length > 0 ) {
    manifest = MethodHelper.initManifest( head, app, '../../?source=pwa' );
    manifest = MethodHelper.extendManifest( manifest, head );

    const json = JSON.stringify( manifest );
    const fs = require( 'fs' );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fs.writeFile( './public/manifest.json', json, ( err ) => {
      if ( err ) {
        // eslint-disable-next-line no-console
        console.error( err )
        return
      }
    });
  }

  if ( app != null && app.searchbarBgColor != null )
    rgbSearchbarColor = MethodHelper.hexToRgb( app.searchbarBgColor );
  if ( app != null && app.designColor )
    rgbDesignColor = MethodHelper.hexToRgb( app.designColor );

  redis.disconnect(false)

  return {
    props: {
      app_data: app_data,
      head: head,
      page: page != null ? page.page : null,
      app: app,
      builder: app != null && app.builder != null ? app.builder : null,
      navigation: navigation,
      properties_count: properties_count,
      locations: locations,
      footer: footer,
      domain: domain,
      subdomain: subdomain,
      parts: parts,
      slug_data: slug_data,
      rgbDesignColor: rgbDesignColor,
      rgbSearchbarColor: rgbSearchbarColor,
      isDesktop: isDesktop,
      propertiesParams: properties_params,
      propertiesFilters: propertiesFilters,
      html_sections: html_sections
    }
  }
})
